import { createContext, useState, useMemo } from "react";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export const UserContext = createContext()

/**
 * @context
 * @description This context contains the user's email
*/
const UserContextProvider = ({children}) => {

  /**
   * @state
   * @type {string}
   * @default ""
   * @description State to save the user's email
  */
  const [user, setUser] = useState(() => {
    return Cookies.get("email") ? Cookies.get("email") : ""
  });

  /**
   * @memo
   * @description Create an object that contains the current state of 'user' and a function to 
   * update it ('setUser').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of user from child components.
  */
  const data = useMemo(() => ({
    user,
    setUser
  }), [user, setUser]);

  return (
    <UserContext.Provider value={data}>
        {children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node
}

export default UserContextProvider