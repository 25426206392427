import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function postLogin
 * @description Sends a POST request to the login endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postLogin(args, token, zone) {
    const config = {
        headers: {
          'Authorization': token
        }
    }
    return axios.post(`${domain}/users/login/?zone=${zone}&${apiParam}`, args, config);
}

/**
 * @function getZones
 * @description Sends a GET request to the block and unblobk endpoint of the API.
 * @param {Object} country - The country selected by the user.
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function getZones() {
  const config = {
      headers: {
        'Authorization': Cookies.get('token')
      }
  }
  return axios.get(`${domain}/users/zone/?${apiParam}`, config).then(res => {
      const responseData = res.data.zones;
      return responseData;
  });
}

export { postLogin, getZones };
