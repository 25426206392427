import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { CountriesContext } from '../../../context/CountriesContext';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { PlantsContext } from '../../../context/PlantsContext';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

/**
 * @component
 * @description This component show the initial modal when the login is made, allow select a country 
 * according with the user
*/
const ModalInitial = ({expirationDate}) => {

    /**
     * @context
     * @description Access to get a global state for CountriesContext, in this case to have 
     * the user's countries
    */
    const {countries} = useContext(CountriesContext);

    /**
     * @context
     * @description Access to update a global state for CountryComponentContext
    */
    const {setCountry} = useContext(CountryComponentContext);

    /**
     * @context
     * @description Access to update a global state for PlantsContext
    */
    const {setPlants} = useContext(PlantsContext);

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    /**
     * @function
     * @name navigatePage
     * @param {string} path - route name
     * @param {string} item - country selected
     * @description Navigate to the route specified
    */
    function navigatePage(path, item) {
        navigate(path)
        const countryUser = {
            normalCountry: item.country_code,
            countryNego: item.country_nego
        }
        setCountry(countryUser)
        const foundObject = countries.find(obj => obj.country === item.country)
        setPlants(foundObject.plants)
        Cookies.set('country_user', JSON.stringify(countryUser), { expires: expirationDate, secure: true, sameSite: 'Strict' });
        Cookies.set('plants_user', foundObject.plants, { expires: expirationDate, secure: true, sameSite: 'Strict' });
    }

  return (
    <div className='w-full h-full'>
        <div className='h-full flex flex-col'>
        {/* Space for the button to access to the app */}
            <div className='w-full flex flex-wrap justify-between'>
                {countries.map((item) => (
                    <div key={item.country} style={{"width":(100/countries.length).toFixed(0) - 1 + "%"}}>
                        <button data-testid={`btn-select-${item.country}`} onClick={() => navigatePage("inventory/summary", item)} className='rounded font-semibold py-2 w-full border-none bg-neutral-300 hover:bg-neutral-200'>{item.country}</button>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

ModalInitial.propTypes = {
    expirationDate: PropTypes.any
}

export default ModalInitial
