import { msalInstance } from '../index';

async function getToken(request) {
    try {
      return await msalInstance.acquireTokenSilent(request);
    } catch (e) {
      try {
        const popupResponse = await msalInstance.acquireTokenPopup(request);
        return popupResponse;
      } catch (popupError) {
        console.error('Error occurred in acquireTokenPopup', popupError);
        throw e;
      }
    }
}

export { getToken };