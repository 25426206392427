import axios from "axios";
import Cookies from "js-cookie";

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function
 * @name formatNumberToMM
 * @description Function to format a number
 * @param {number} number - Number specified
*/
function formatNumberToMM(number) {
    if (typeof number !== 'number') {
      return '0';
    }
    
    const mil = 1000;
    const millon = 1000000;
    const milMillon = 1000000000;

    if (number >= milMillon) {
        const formattedNumber = (number / milMillon).toFixed(2);
        return `${formattedNumber} MM`;
    }

    if (number >= millon) {
        const formattedNumber = (number / millon).toFixed(2);
        return `${formattedNumber} M`;
    }

    if (number >= mil) {
        const formattedNumber = (number / mil).toFixed(2);
        return `${formattedNumber} K`;
    }
  
    return `${number.toFixed(2)}`;
}

function addThousandPoints(number) {
    let isNegative = number < 0;
    let num = Math.abs(number).toFixed(2);
    let [integer, decimal] = num.split(".");
    let result = '';
    while (integer.length > 3) {
        result = ',' + integer.slice(-3) + result;
        integer = integer.slice(0, integer.length - 3);
    }
    result = integer + result;
    if (isNegative) {
        result = '-' + result;
    }
    return Number.isInteger(number) ? result : result + '.' + decimal;
}

function formatCurrency(number, notationText, currency, locale, changeRateSelected) {
    const newNumber = number * (changeRateSelected ?? 1)
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        notation: notationText,
        currency: currency,
    });
    return formatter.format(newNumber);
}

function formatNewNumber(number, notationText) {
    const formatter = new Intl.NumberFormat(undefined, {
        notation: notationText,
    });
    return formatter.format(number);
}

function removeAllCookies() {
    Cookies.remove('email');
    Cookies.remove('token');
    Cookies.remove('country_user');
    Cookies.remove('permissions');
    Cookies.remove('plants_user');
    Cookies.remove('role');
    Cookies.remove('zone_user');
    Cookies.remove('accessToken');
}

function getRatesChanges() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/currency/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

export { formatNumberToMM, addThousandPoints, formatCurrency, formatNewNumber, removeAllCookies, getRatesChanges };
