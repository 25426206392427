import React, {useState, useEffect, useContext} from 'react';
import { CurrencyContext } from '../../context/CurrencyContext';
import Cookies from 'js-cookie';
import IPALogo from '../../images/IPALogo.svg'
import ABILogo from '../../images/ABILogo.svg'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { IconButton, createTheme, ThemeProvider, Select, MenuItem, InputLabel, FormControl, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import { getRatesChanges } from '../../models/generics';

const theme = createTheme({
    palette: {
      white: {
        main: '#FFF'
      },
    },
});

/**
 * @component
 * @description This component show the superior bar with ipa logo and ABI logo
*/
const NavBars = () => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
     */
    const { t } = useTranslation();

    const { instance, accounts } = useMsal();

    const {currency, setCurrency, setLocale, changeRates, setChangeRates, setChangeRateSelected} = useContext(CurrencyContext)

    const money = [
        {code:"USD", locale:"en-US"},
        {code:"COP", locale:"es-CO"},
        {code:"EUR", locale:"en-ES"},
        {code:"MXN", locale:"en-MX"},
        {code:"PEN", locale:"es-PE"},
        {code:"HNL", locale:"es-HN"},
        {code:"PAB", locale:"es-PA"},
        {code:"GBP", locale:"en-GB"},
        {code:"CHF", locale:"de-CH"}
    ]

    const languages = [
        {code:"en", name:t("english")},
        {code:"es", name:t("spanish")},
        {code:"po", name:t("portuguese")}
    ]

    const handleLogOut = () => {
        Cookies.remove('email');
        Cookies.remove('token');
        Cookies.remove('country_user');
        Cookies.remove('permissions');
        Cookies.remove('plants_user');
        Cookies.remove('role');
        Cookies.remove('zone_user');
        Cookies.remove('accessToken');
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            account: accounts[0]
        })
    }

    /**
     * @state
     * @type {string}
     * @default en
     * @description Local State to know what language was selected
    */
    const [languageSelected, setLanguageSelected] = useState("en");

    /**
     * @function
     * @name handleClick
     * @description Function to change the value of isToggled
    */
    const handleClick = (language) => {
        setLanguageSelected(language)
    };

    const handleCurrencyChange = (currency, locale) => {
        const selected = changeRates[currency]
        setChangeRateSelected(selected)
        setCurrency(currency);
        setLocale(locale)
    };

    /**
     * @effect
     * @description This effect is executed when the isToggled state is updated. 
     * Use the i18next library to change the language of the texts in the app
    */
    useEffect(() => {
        i18next.changeLanguage(languageSelected)
    }, [languageSelected]);

    useEffect(() => {
        getRatesChanges().then((res) => {
            if (res.length > 0) {
                const rates = res.reduce((acc, item) => {
                    acc[item.currency] = item.exchange_rate;
                    return acc;
                }, {});
                setChangeRates(rates);
                const selected = rates[currency]
                setChangeRateSelected(selected)
            } else {
                setChangeRates(null);
                setChangeRateSelected(1)
            }
        }).catch((error) => {
            setChangeRates(null);
            setChangeRateSelected(1)
        })
    }, []);

    return (
        <div className='bg-neutral-500 h-0.50/5 flex flex-row justify-between w-full px-8 gap-4'>
            {/* Space for the IPA Logo and ABI Logo */}
            <div className='flex items-center w-1/2 gap-4'>
                <div>
                    <img src={IPALogo} alt="logo"/>
                </div>
                <div>
                    <img src={ABILogo} alt="logo"/>
                </div>
            </div>
            {/* Space for the language toggle */}
            <div className='flex items-center justify-end w-1/2 gap-3'>
                <div className="flex justify-between bg-neutral-200 rounded-full h-1/2 w-1/2 xl:w-2/5 duration-500 gap-1">
                    {languages.map((item, index) => (
                        <button key={index+1} onClick={() => handleClick(item.code)} className={`${languageSelected === item.code ? 'bg-white duration-500': 'hover:bg-neutral-300 duration-500'} rounded-full flex justify-center items-center w-2/6 cursor-pointer px-2`}>
                            <p className='mb-0 text-xs xl:text-sm 4xl:text-xl font-bold'>{item.name}</p>
                        </button>
                    ))}
                </div>
                <div className="w-1.5/5">
                    <Tooltip title={t("currencyError")} disableHoverListener={changeRates !== null}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="select-currency-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg !text-slate-400'>Currency</InputLabel>
                            <Select data-testid="currency-select" disabled={changeRates === null} value={currency} className='disabled:!text-black !bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-initial-month-label" id="select-initial-month" label={t("anomaliesModule.initial_month")}>
                                {money.map((item, index) => (
                                    <MenuItem data-testid="currency-option" key={index+1} value={item.code} onClick={() => handleCurrencyChange(item.code, item.locale)}>
                                        {t(`currencies.${item.code}.label`)} - {item.code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </div>
                <ThemeProvider theme={theme}>
                    <Tooltip title={t("logout")}>
                        <IconButton color="white" size="large" aria-label="logout" data-testid="logout-btn" onClick={() => handleLogOut()}>
                            <LogoutIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </ThemeProvider>
            </div>
        </div>
    );
}

export default NavBars;
